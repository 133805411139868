/*
 *
 * ChatRoom constants
 *
 */
/* eslint-disable no-unused-vars */
import { BD_LINK, BG_WHITE } from '@/constants/colors';
import { VIEWING_STATUS } from '@/services/Video/constants';
import { ViewingStatus } from '@/services/Video/types';

import messages from './messages';

/**
 * action type
 */
export const ASSIGN_ASSISTANT = 'app/ChatRoom/ASSIGN_ASSISTANT';
export const RECEIVE_MESSAGE: string = 'app/ChatRoom/RECEIVE_MESSAGE';
export const RECEIVE_POKE: string = 'app/ChatRoom/RECEIVE_POKE';
export const APPEND_CHAT: string = 'app/ChatRoom/APPEND_CHAT';
export const APPEND_REACTION: string = 'app/ChatRoom/APPEND_REACTION';
export const APPEND_GIFT: string = 'app/ChatRoom/APPEND_GIFT';
export const APPEND_LUCKYBAG: string = 'app/ChatRoom/APPEND_LUCKYBAG';
export const APPEND_POKE: string = 'app/ChatRoom/APPEND_POKE';
export const APPEND_LABOR_REWARD = 'app/ChatRoom/APPEND_LABOR_REWARD';
export const APPEND_SYSTEM_COMMENT: string =
  'app/ChatRoom/APPEND_SYSTEM_COMMENT';
export const APPEND_ARMY_GIFT: string = 'app/ChatRoom/APPEND_ARMY_GIFT';
export const APPEND_ARMY_SUBSCRIPTION: string =
  'app/ChatRoom/APPEND_ARMY_SUBSCRIPTION';
export const POST_CHAT: string = 'app/ChatRoom/POST_CHAT';
export const POST_BARRAGE: string = 'app/ChatRoom/POST_BARRAGE';
export const GET_BARRAGE_INFO: string = 'app/ChatRoom/GET_BARRAGE_INFO';
export const SET_BARRAGE_MODE: string = 'app/ChatRoom/SET_BARRAGE_MODE';
export const UPDATE_CHAT: string = 'app/ChatRoom/UPDATE_CHAT';
export const PUSH_BANNER_MESSAGE: string = 'app/ChatRoom/PUSH_BANNER_MESSAGE';
export const SHIFT_BANNER_MESSAGE: string = 'app/ChatRoom/SHIFT_BANNER_MESSAGE';
export const SHOW_CHECKING_MESSAGE: string =
  'app/ChatRoom/SHOW_CHECKING_MESSAGE';
export const UPDATE_SUBMIT_CHAT_FOCUS: string =
  'app/ChatRoom/UPDATE_SUBMIT_CHAT_FOCUS';
export const UPDATE_SUBMIT_CHAT_MODE: string =
  'app/ChatRoom/UPDATE_SUBMIT_CHAT_MODE';

/**
 * styles
 */
export const STREAMER_COLOR: string = '#33ceb0';
export const VIP_COLOR: string = '#ff15d3';
export const GUARDIAN_COLOR: string = '#33cccc';
export const SYSTEM_COLOR: string = '#6f6777';

export const VIP_BANNER_BACKGROUND: string = VIP_COLOR;
export const GUARDIAN_BANNER_BACKGROUND: string = '#42f1f6';

export const VIP_BANNER_COLOR: string = BG_WHITE;
export const GUARDIAN_BANNER_COLOR: string = '#F63BB4';

export const FOLLOW_TEXT_COLOR: string = BD_LINK;

// Pubnub 給 #5928232D 在 FE 會用 transfer8bitHexCode 轉
export const DEFAULT_COMMENT_BG_COLOR = '#28232D59';
export const DEFAULT_STREAMER_COMMENT_BG_COLOR = '#00A38EB3';
export const DEFAULT_GUARDIAN_COMMENT_BG_COLOR = '#1DCBE4B3';

/**
 * user types
 */
export const USER_VIP: string = 'USER_VIP';
export const USER_STREAMER: string = 'USER_STREAMER';
export const USER_GUARDIAN: string = 'USER_GUARDIAN';
export const USER_NORMAL: string = 'USER_NORMAL';
export const USER_SYSTEM: string = 'USER_SYSTEM';

/**
 * message types
 */
export const REACTION_TYPE: string = 'REACTION';
export const GIFT_TYPE: string = 'GIFT';
export const SYSTEM_TYPE = 'SYSTEM';
export const LABOR_REWARD_TYPE = 'LABOR_REWARD';
export const ARMY_GIFT_TYPE = 'ARMY_GIFT';
export const ARMY_SUBSCRIPTION_TYPE = 'ARMY_SUBSCRIPTION';

export const TREASURE_CHEST_GLOBAL_BROADCAST_KEY: string =
  'treasure_chest_global_jackpot_BE';
export const SLOT_GLOBAL_BROADCAST_KEY: string = 'SLOT_JACKPOT_MSG';
export const SLOT_AND_TREASURE_CHEST_MESSAGES = {
  [TREASURE_CHEST_GLOBAL_BROADCAST_KEY]: messages.globalTreasureChestMessage,
  [SLOT_GLOBAL_BROADCAST_KEY]: messages.globalSlotMessage,
};

/**
 * gift types
 */
export const GIFT_NORMAL: string = 'GIFT_NORMAL';
export const GIFT_LUCKYBAG: string = 'GIFT_LUCKYBAG';
export const GIFT_POKE: string = 'GIFT_POKE';
export const GIFT_SLOTS: string = 'GIFT_SLOTS';

/**
 * tab types
 */
export const TAB_SLOT: number = 1;
export const TAB_TREASURE_CHEST: number = 2;
export const TAB_AXE_TREASURE_CHEST: number = 3;

/**
 * others
 */
export const MAX_CHATS: number = 250;
export const MOBILE_MAX_CHATS: number = 50;
export const BANNER_MESSAGE_DISPLAY_TIME: number = 5000;
export const BROADCAST_MESSAGE_DISPLAY_TIME: number = 10000;
export const BROADCAST_SLOTS_SENDER_DISPLAY_NAME_INDEX: number = 0;
export const BROADCAST_SLOTS_POINTS_INDEX: number = 1;
export const MOBILE_INPUT_HEIGHT: number = 36; // px
export const MOBILE_FOCUSED_INPUT_HEIGHT: number = 52; // px
export const MOBILE_FOCUSED_INPUT_MULTILINE_HEIGHT: number = 72; // px
export const CHAT_BUFFER_TIME = 500; // buffer and emit every 500ms

export const DISABLED_STATUS: ViewingStatus[] = [
  VIEWING_STATUS.BLOCKED,
  VIEWING_STATUS.NOT_APPROVED,
];

export enum SUBMIT_CHAT_MODE {
  SNACK = 'snack',
  BARRAGE = 'barrage',
  NORMAL = 'normal',
}

export enum SNACK_STATUS {
  NORMAL = 'normal',
  DONE = 'done',
}

export enum BARRAGE_STATUS {
  NORMAL = 'normal',
  ERROR = 'error',
}

export const SUBMIT_CHAT_MODE_CONFIG: {
  [key: string]: any;
} = {
  [SUBMIT_CHAT_MODE.SNACK]: {
    isNeedTip: true,
    tips: {
      [SNACK_STATUS.NORMAL]: 'snack_livestream_description',
      [SNACK_STATUS.DONE]: 'snack_livestream_description_done',
    },
  },

  [SUBMIT_CHAT_MODE.BARRAGE]: {
    isNeedTip: true,
    tips: {
      [BARRAGE_STATUS.NORMAL]: 'barrage_streamroom_length_reminder',
      [BARRAGE_STATUS.ERROR]: 'without_babycoins_barrage',
    },
  },
};
